import React from "react";
export default function Logo(props) {
  return (
    <svg
      width="306"
      height="132"
      viewBox="0 0 306 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M94.92 105.96C93 105.96 91.368 105.32 90.024 104.04C88.68 102.696 88.008 101.064 88.008 99.144C88.008 97.16 88.648 95.528 89.928 94.248C91.272 92.904 92.936 92.232 94.92 92.232C96.904 92.232 98.536 92.904 99.816 94.248C101.16 95.528 101.832 97.16 101.832 99.144C101.832 101.064 101.16 102.696 99.816 104.04C98.536 105.32 96.904 105.96 94.92 105.96ZM116.426 34.44H127.274V95.4H147.53V105H116.426V34.44ZM159.111 34.44H169.863V105H159.111V34.44ZM196.546 59.304L196.834 66.984V105H186.082V34.344H187.426L228.898 80.616L228.514 72.552V34.44H239.266V105H237.826L196.546 59.304ZM270.558 73.416L264.798 80.52V105H254.238V34.44H264.798V65.928L288.99 34.44H301.854L277.566 65.448L305.31 105H292.158L270.558 73.416Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 21C6.71576 21 0 27.7158 0 36V96C0 104.284 6.71576 111 15 111H59C67.2842 111 74 104.284 74 96V36C74 27.7158 67.2842 21 59 21H15ZM20 31C14.4772 31 10 35.4772 10 41V91C10 96.5228 14.4772 101 20 101H54C59.5228 101 64 96.5228 64 91V41C64 35.4772 59.5228 31 54 31H20Z"
        fill="#CE1212"
      />
      <rect x="10" y="3" width="54" height="14" rx="5" fill="#CE1212" />
    </svg>
  );
}
